import React from 'react';
import { Link } from 'react-router-dom';


const PrivacyPolicy = () => {

    const titleStyle = {
        fontFamily: 'degular-display, sans-serif',
        fontSize: '36px',
        fontWeight: '600',
        lineHeight: '48px',
        letterSpacing: '0.02em',
        textAlign: 'left',
    };

    const subtitleStyle = {
        fontFamily: 'degular-display, sans-serif',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '25px',
        letterSpacing: '0.02em',
        textAlign: 'left',
      };
      
      const paragraphStyle = {
        fontFamily: 'degular-display, sans-serif',
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '18px',
        letterSpacing: '0.02em',
        textAlign: 'left',
      };

      const listItemStyle = {
        ...paragraphStyle, 
        paddingLeft: '0px',
        textIndent: '-10px', 
        marginTop: '6px',
      };
      

    return (
        <div className="mx-auto flex flex-col items-center gap-14 max-w-[660px] min-w-[320px] pb-20">
            <div className="flex flex-col items-start gap-3.5 px-4">
                <div style={titleStyle} className="text-zinc-800 w-full">Privacy Policy</div>
                <div>
                <p style={paragraphStyle}>Thank you for visiting www.designpath.co, a Revolverhaus LLC website, and reviewing our Privacy Statement. Design Path Co does not collect your personal information when you visit our websites unless you choose to provide that information to us. Design Path Co does not sell, exchange, or release your personal information to outside parties.</p>
                <p style={paragraphStyle} className="mt-6">Design Path Co reserves the right to change or update the terms of this Privacy Policy, so site users are encouraged to review this page from time to time. If you do not agree to this Privacy Policy, as modified from time to time, please do not visit this site.</p>
                <h2 style={subtitleStyle} className="mt-6">If You Send Us Personal Information</h2>
                <p style={paragraphStyle}>Design Path Co will only collect personal information which you knowingly and voluntarily provide by, for example, sending emails, completing contact forms, or registering for notifications.</p>
                <p style={paragraphStyle}>Design Path Co uses outside vendors to provide certain services on our behalf, such as e-mail distribution, website comments, and job boards. These third-party vendors will have access to the information needed to perform their functions but may not use that information for any other purpose.</p>
                <p style={paragraphStyle} className="mt-6">We will not share your name or information outside the company unless you affirmatively authorize us to do so by opting-in.</p>
                <h2 style={subtitleStyle} className="mt-6">Information Collected and Stored Automatically</h2>
                <p style={paragraphStyle}>If you visit our website, we automatically gather and store the following information about your visit so that we can track the use of our website in order to make improvements. This information is stored and used in aggregate form only, and is not used to contact you personally:</p>
                
                <ul style={{ listStyleType: 'disc', marginLeft: '30px',}} className="mt-6">
                    <li style={listItemStyle}>The IP address from which you access our website;</li>
                    <li style={listItemStyle}>The name of the domain from which you access the Internet (for example, gmail.com, if you are connecting from a Google Online account);</li>
                    <li style={listItemStyle}>The type of browser and operating system used to access our website;</li>
                    <li style={listItemStyle}>The date and time you access our site;</li>
                    <li style={listItemStyle}>The pages, files, documents, and links that you visit;</li>
                    <li style={listItemStyle}>The domain of the website which referred you to this website.</li>
                    </ul>
                <h2 style={subtitleStyle} className="mt-6">Cookies</h2>
                <p style={paragraphStyle}>
                    We are able to offer Design Path Co to readers at no cost through advertising, and those ads use{" "}
                    <a href="https://en.wikipedia.org/wiki/HTTP_cookie" style={{ textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">
                        cookies
                    </a>,{" "}
                    which are small pieces of information sent to your browser by a website that you visit. These cookies do not collect personally identifiable information. Cookies are used to track usage patterns, traffic trends, and customer behavior, as well as to record other information from the website.
                    </p>                
                <p style={paragraphStyle} className="mt-6">The data is retained for as long as necessary to support the mission of Design Path Co’s website. You may set your browser to refuse cookies from any website that you visit. If you choose to do so, you may still gain access to most of Design Path Co’s feature, however we cannot guarantee that all parts of the website will function properly.</p>
                <h2 style={subtitleStyle} className="mt-6">Children and Privacy</h2>
                <p style={paragraphStyle}>Design Path Co’s website is intended to be accessible and enjoyed by general audiences. We do not collect personally identifiable information from children under the age of thirteen (13). Users under 13 cannot create an account or use any part of the site that requires the submission of personally identifiable information, like email or name.</p>
                <h2 style={subtitleStyle} className="mt-6">Security</h2>
                <p style={paragraphStyle}>Design Path Co takes reasonable steps to design and manage our websites’ technology to ensure that its information technology systems, applications, and information technology infrastructure are secure and confidential. However, due to the design of the internet and other factors outside of our control, we cannot guarantee that communications between you and our servers will be free from unauthorized access by third parties.</p>
                <h2 style={subtitleStyle} className="mt-6">Linking</h2>
                <p style={paragraphStyle}>Within Design Path Co’s websites are advertisements, widgets, and other content links to non-Design Path Co websites. These sites operate independently of Design Path Co and have their own privacy policies. Revolverhaus LLC is not responsible for the privacy practices or the content of non-Design Path Co websites, and such link is not intended to be an endorsement of the non-Design Path Co website or its content. For your protection, you should review the privacy policy of the site you linked to from our website.</p>
                <h2 style={subtitleStyle} className="mt-6">Design Path Co is operated by:</h2>
                <p style={paragraphStyle}>Revolverhaus, LLC</p>
                <p style={paragraphStyle}>2108 N St Ste N</p>
                <p style={paragraphStyle}>Sacramento, CA 95816</p>
                <p style={paragraphStyle}>Email: <a href="mailto:hello@designpath.co">hello@designpath.co</a></p>
                <p style={paragraphStyle} className="mt-4">Last revised: Feb 26, 2024</p>
            </div>
                <div className="w-full border-t border-stone-200"></div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
