import React, { useState, useEffect } from 'react';
import CustomDropdown from './CustomDropdown';
import Confetti from 'react-dom-confetti';
import '../ContactForm.css';
import mailLogo from '../mailicon.svg';
import phoneLogo from '../phoneicon.svg';



const ContactPage = () => {

  const useIsMobile = () => {
    // Initial state is set based on window width
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };
  
      // Add event listener
      window.addEventListener('resize', handleResize);
  
      // Clean up function to remove event listener
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return isMobile;
  };

  const isMobile = useIsMobile();


  const options = [
    { value: 'topic1', label: 'Discuss a New Project' },
    { value: 'topic2', label: 'General Inquiry' },
    { value: 'topic3', label: 'Past Projects' },
    { value: 'topic4', label: 'General Feedback' },
    { value: 'topic5', label: 'Other' },
  ];

  const [selectedOption, setSelectedOption] = useState(null);
  const [focusedField, setFocusedField] = useState('');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [organization, setOrganization] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [showGeneralError, setShowGeneralError] = useState(false);
  const [showSubmissionError, setShowSubmissionError] = useState(false);
  const [resetDropdownTrigger, setResetDropdownTrigger] = useState(false);
  const [resetDropdownCounter, setResetDropdownCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [confettiTrigger, setConfettiTrigger] = useState(false);

  const confettiConfig = {
    angle: 90,
    spread: 500,
    startVelocity: 40,
    elementCount: 200,
    dragFriction: 0.15,
    duration: 5000,
    stagger: 4,
    width: "12px",
    height: "12px",
    zIndex: "99999999",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };




  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
  
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };
  
  const handleNameChange = (e) => {
    const newValue = e.target.value;
    setName(newValue);

    if (!newValue.trim()) {
      setFormErrors(prevErrors => ({ ...prevErrors, name: 'Name is required.' }));
    } else {
      setFormErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        delete newErrors.name;
        return newErrors;
      });
    }
  };
  
  
  const handleEmailChange = (e) => {
    const newValue = e.target.value;
    setEmail(newValue);
    if (formErrors.email) {
      setFormErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        delete newErrors.email;
        return newErrors;
      });
    }
  };
  
  const handleMessageChange = (e) => {
    const newValue = e.target.value;
    setMessage(newValue);
    if (formErrors.message) {
      setFormErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        delete newErrors.message;
        return newErrors;
      });
    }
  };
  



  const handleSelect = (option) => {
    setSelectedOption(option.label);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setShowGeneralError(false);
    setShowSubmissionError(false);

    let errors = {};

    if (!selectedOption) errors.selectedOption = 'Please select a topic.';
    if (!name.trim()) errors.name = 'Name is required.';
    if (!email.trim()) errors.email = 'Email is required.';
    if (!message.trim()) errors.message = 'Message is required.';

    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      setShowGeneralError(true);
      setIsLoading(false);
      return;
    }
  
    const formData = {
      'entry.1322691074': selectedOption,
      'entry.1913950834': name, 
      'entry.1062123461': email,
      'entry.297322471': phone,
      'entry.1123976083': organization,
      'entry.829990281': message,
    };
  
    const formBody = new URLSearchParams(formData).toString();
    try {
      await fetch('https://docs.google.com/forms/d/e/1FAIpQLSeeaxbT-rwa-EmQVt4ZXDbox1hVk5wQ5mRqbeXPW3zfxIVWEQ/formResponse', {
      method: 'POST',
      body: formBody,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      mode: 'no-cors',
    });
      console.log('Form submitted');

      setName('');
      setEmail('');
      setPhone('');
      setOrganization('');
      setMessage('');
      setSelectedOption(null);
      setResetDropdownTrigger(!resetDropdownTrigger);
      setResetDropdownCounter(count => count + 1);


      setIsSubmitted(true);
      setConfettiTrigger(true);
      setTimeout(() => {
        setIsSubmitted(false);
        setConfettiTrigger(false);
      }, 5000);
    } catch (error) {
        console.log('Form submission error');
        setShowSubmissionError(true);
    } finally {
        setIsLoading(false);
    }
  };
  


  const getTitleStyle = {
    fontFamily: 'degular-display, sans-serif',
    fontSize: isMobile ? '44px' : '36px',
    fontWeight: 500,
    lineHeight: '48px',
    letterSpacing: '0.02em',
    textAlign: 'left',
  };

  const paragraphStyle = {
    fontFamily: 'degular-display, sans-serif',
    fontSize: isMobile ? '22px' : '16px',
    fontWeight: 300,
    lineHeight: isMobile ? '29.19px' : '21.23px',
    letterSpacing: '0.03em',
    textAlign: 'left',
  };

  const formStyle = {
    fontFamily: 'degular-display, sans-serif',
    fontSize: isMobile ? '22px' : '16px',
    fontWeight: 400,
    lineHeight: '21.23px',
    letterSpacing: '0.04em',
    textAlign: 'left',
  };

  const emailUsStyle = {
    fontFamily: 'degular-display, sans-serif',
    fontSize: isMobile ? '20px' : '16px',
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '0.04em',
    textAlign: 'left',
  };
  
  const emailStyle = {
    fontFamily: 'degular-display, sans-serif',
    fontSize: isMobile ? '20px' : '16px',
    fontWeight: 300,
    lineHeight: '21px',
    letterSpacing: '0.05em',
    textAlign: 'left',
    textDecoration: 'underline',
  };
  
  const callUsStyle = {
    fontFamily: 'degular-display, sans-serif',
    fontSize: isMobile ? '20px' : '16px',
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '0.04em',
    textAlign: 'left',
  };
  
  const phoneNumberStyle = {
    fontFamily: 'degular-display, sans-serif',
    fontSize: isMobile ? '20px' : '16px',
    fontWeight: 300,
    lineHeight: '21px',
    letterSpacing: '0.05em',
    textAlign: 'left',
    textDecoration: 'underline',
  };

const messageStyle = {
  fontFamily: 'degular-display, sans-serif',
  fontSize: isMobile ? '20px' : '14px',
  fontWeight: '400',
  lineHeight: '19px',
  letterSpacing: '0.04em',
  textAlign: 'center',
};
const customButtonStyle = {
  fontFamily: 'degular-display, sans-serif',
  fontSize: isMobile ? '22px' : '16px',
  fontWeight: '500',
  lineHeight: '21px',
  letterSpacing: '0.05em',
  textAlign: 'center',
  borderRadius: '9999px',
  height: isMobile ? '60px' : '40px',
  background: 'linear-gradient(21.99deg, #FF4D00 15.74%, #FF6914 86.97%)',
};

const handleFocus = fieldName => {
  setFocusedField(fieldName);
};

const handleBlur = fieldName => {
  setFocusedField('');
};


console.log(formErrors);

  return (
    
    <div className="flex flex-col items-center justify-center pb-14 sm:pb-0">
      <div style={{ position: 'relative', zIndex: 99999999 }}>
        <Confetti active={confettiTrigger} config={confettiConfig} />
      </div>
      <div className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto px-4 pt-3">
        <h1 style={getTitleStyle} className="text-zinc-800 mb-5 md:mb-5 lg:mb-5 xl:mb-5">Get In Touch</h1>
        <div className=" flex flex-col items-center justify-start gap-3">
        <p className="text-zinc-800 w-full" style={paragraphStyle}>
          We’d love to hear from you. We usually respond within 24 hours, but may on occasion take an additional 1 to 2 business days.
          <br /><br />
          We never share or sell your personal info.
        </p>
        <form className="w-full flex flex-col gap-4" style={formStyle} onSubmit={handleSubmit}>
            <CustomDropdown options={options} onSelect={handleSelect} selectedOption={selectedOption} hasError={formErrors.selectedOption ? true : false} resetCounter={resetDropdownCounter} isMobile={isMobile}/>
            <div className="relative">
              <input
                type="text"
                className={`inputField w-full h-10 px-3.5 py-2 bg-stone-50 rounded text-zinc-800 ${formErrors.name ? 'border-red-500' : 'border-gray-200'}`}
                style={{
                  borderColor: formErrors.name ? '#f87171' : focusedField === 'name' ? 'rgba(255, 77, 0, 1)' : '#e5e7eb',
                  backgroundColor: focusedField === 'name' ? 'rgba(250, 250, 249, 1)' : '',
                  letterSpacing: '.04em'
                }}
                placeholder=" "
                value={name}
                onChange={handleNameChange}
                onFocus={() => handleFocus('name')}
                onBlur={() => handleBlur('name')}
              />
              <label
                className={`inputLabel ${focusedField === 'name' || name.trim() ? 'focused' : ''}`}
                style={{
                  color: (focusedField === 'name' || name.trim()) && !(focusedField !== 'name' && name.trim()) ? '#FF4D00' : '#A8A8A8',
                }}
              >
                Your Name
              </label>
            </div>
            <div className="relative">
              <input
                type="email"
                className={`inputField w-full h-10 px-3.5 py-2 bg-stone-50 rounded text-zinc-800 ${formErrors.email ? 'border-red-500' : 'border-gray-200'}`}
                style={{
                  borderColor: formErrors.email ? '#f87171' : focusedField === 'email' ? 'rgba(255, 77, 0, 1)' : '#e5e7eb',
                  backgroundColor: focusedField === 'email' ? 'rgba(250, 250, 249, 1)' : '',
                  letterSpacing: '.04em'
                }}
                placeholder=" "
                value={email}
                onChange={handleEmailChange}
                onFocus={() => handleFocus('email')}
                onBlur={() => handleBlur('email')}
              />
              <label
                className={`inputLabel ${focusedField === 'email' || email.trim() ? 'focused' : ''}`}
                style={{
                  color: (focusedField === 'email' || email.trim()) && !(focusedField !== 'email' && email.trim()) ? '#FF4D00' : '#A8A8A8',
                }}
              >
                Email Address
              </label>
            </div>

            <div className="relative">
              <input
                type="tel"
                autocomplete="tel"
                className="inputField w-full h-10 px-3.5 py-2 bg-stone-50 rounded text-zinc-800"
                style={{
                  letterSpacing: '.04em'
                }}
                placeholder=" "
                value={phone}
                onChange={(e) => setPhone(formatPhoneNumber(e.target.value))}
                onFocus={() => handleFocus('phone')}
                onBlur={() => handleBlur('phone')}
              />
              <label
                className={`inputLabel ${focusedField === 'phone' || phone.trim() ? 'focused' : ''}`}
                style={{
                  color: (focusedField === 'phone' || phone.trim()) && !(focusedField !== 'phone' && phone.trim()) ? '#FF4D00' : '#A8A8A8',
                  
                }}
              >
                Phone Number (Optional)
              </label>
            </div>
            <div className="relative">
              <input
                type="text"
                className="inputField w-full h-10 px-3.5 py-2 bg-stone-50 rounded text-zinc-800"
                style={{
                  letterSpacing: '.04em'
                }}
                placeholder=" "
                value={organization}
                onChange={(e) => setOrganization(e.target.value)}
                onFocus={() => handleFocus('organization')}
                onBlur={() => handleBlur('organization')}
              />
              <label
                className={`inputLabel ${focusedField === 'organization' || organization.trim() ? 'focused' : ''}`}
                style={{
                  color: (focusedField === 'organization' || organization.trim()) && !(focusedField !== 'organization' && organization.trim()) ? '#ff6347' : '#A8A8A8',
                }}
              >
                Organization Name (Optional)
              </label>
            </div>
            <div className="relative">
            <textarea
              className="inputField textarea w-full px-3.5 py-2 bg-stone-50 rounded text-zinc-800" 
              style={{
                borderColor: formErrors.message ? '#f87171' : focusedField === 'message' ? 'rgba(255, 77, 0, 1)' : '#e5e7eb',
                backgroundColor: focusedField === 'message' ? 'rgba(250, 250, 249, 1)' : '',
                letterSpacing: '.04em',
              
              }}
              placeholder=" "
              value={message}
              onChange={handleMessageChange}
              onFocus={() => handleFocus('message')}
              onBlur={() => handleBlur('message')}
            ></textarea>
            <label
              className={`inputLabel ${focusedField === 'message' || message.trim() ? 'focused' : ''}`}
              style={{
                color: (focusedField === 'message' || message.trim()) && !(focusedField !== 'message' && message.trim()) ? '#FF4D00' : '#A8A8A8',
                top: '10px',
              }}
            >
              Your Message
            </label>
          </div>

            <button style={customButtonStyle} className="w-full h-10 py-2 bg-custom-orange rounded text-stone-100 hover:brightness-105 customButton">
              {isLoading ? <div className="spinner"></div> : 'Send Message'}
            </button>

            {isSubmitted && (
              <div className=" text-center mt-2" style={messageStyle}>
                <span className="text-emerald-500">Message sent! We’ll be in touch soon.</span>
                <span className="text-red-600"> 👍</span>
              </div>
            )}
            {showGeneralError && (
              <div className=" text-center mt-2" style={messageStyle}>
                <span className="text-red-500"> Fix the issues above and try again. </span>
              </div>
            )}
            {showSubmissionError && (
              <div className=" text-center mt-2" style={messageStyle}>
                <span className="text-red-500">Submission Error. Please try again.</span>
                <span className="text-red-600"> 😔</span>
              </div>
            )}
                      
            <div className="w-full border-t border-[#E8E0DC] mt-2"></div>
            <div className="py-2">
              <div className="w-80 h-24 relative">
                <div className="w-80 h-10 left-0 top-0 absolute justify-start items-start gap-1.5 inline-flex">
                  <div className="w-9 h-10 relative">
                    <img src={mailLogo} alt="Mail" className="w-7 h-8 left-0 top-[0px] absolute" />
                  </div>
                  <div className="w-64 h-10 relative">
                    <div style={emailUsStyle} className="w-64 left-0 top-0 absolute text-zinc-800">Email us:</div>
                    <a href="mailto:hello@designpath.co" style={emailStyle} className="w-64 h-5 left-0 top-[19px] absolute text-zinc-800 hover-effect">hello@designpath.co</a>
                  </div>
                </div>
                <div className="w-80 h-10 left-0 top-[51px] absolute justify-start items-start gap-1.5 inline-flex">
                  <div className="w-9 h-10 relative">
                    <img src={phoneLogo} alt="Phone" className="w-7 h-8 left-0 top-[4px] absolute" />
                  </div>
                  <div className="w-64 h-10 relative">
                    <div style={callUsStyle} className="w-64 left-0 top-0 absolute text-zinc-800">Call us:</div>
                    <a href="tel:+19092062453" style={phoneNumberStyle} className="w-64 h-5 left-0 top-[19px] absolute text-zinc-800 hover-effect">(909) 206-2453</a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;