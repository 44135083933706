import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import dogImage from '../dog.png';

const NotFoundPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    container: {
      maxWidth: isMobile ? '430px' : '660px',
      margin: '0 auto',
      padding: isMobile ? '0 18px' : '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: isMobile ? '12px' : '6px',
    },
    heading: {
        fontFamily: 'degular-display, sans-serif',
        fontSize: isMobile ? '60px' : '65px', 
        fontWeight: '500',
        lineHeight: isMobile ? '48.6px' : '52.64px',
        letterSpacing: '0.02em',
        textAlign: 'center',
        color: '#1F2937',
    },
    paragraph: {
      fontFamily: 'degular-display, sans-serif',
      fontSize: isMobile ? '24px' : '22px',
      fontWeight: '300',
      lineHeight: '29.19px',
      letterSpacing: isMobile ? '0.03em' : '0.03em',
      textAlign: 'center',
      color: '#1F2937',
    },
    buttonTextStyle: {
        marginTop: '10px',
      fontFamily: 'degular-display, sans-serif',
      fontSize: isMobile ? '20px' : '16px',
      fontWeight: '400',
      lineHeight: '21.23px',
      letterSpacing: '0.04em',
      textAlign: 'center',
      color: '#FFFFFF',
      backgroundColor: '#323232',
      padding: '10px 35px',
      borderRadius: '9999px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: isMobile ? '50px' : '40px',
    }
  };

  const gradientServicesButtonStyle = {
    background: 'linear-gradient(21.99deg, #474747 0%, #323232 100%)',
  }

  const servicesButtonStyle = {
    ...styles.buttonTextStyle,
    ...gradientServicesButtonStyle,
  }

  return (
    <div style={{ paddingTop: '70px' }} className="flex justify-center items-center">

    <div style={styles.container}>
    <img src={dogImage} alt="Dog" style={{ maxWidth: '100%', height: 'auto' }} />
      <h1 style={styles.heading}>Doops!</h1>
      <p style={styles.paragraph}>
        It looks like this page doesn’t exist or has been removed. 😔
      </p>
      <Link to="/welcome" className="no-underline customButton" style={servicesButtonStyle}>
        Go Home
      </Link>
    </div>
    </div>
  );
};

export default NotFoundPage;
