import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import desktopImage from '../welcomeImage.png';
import mobileImage from '../halfImage.png';

const Welcome = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showDesktopImage, setShowDesktopImage] = useState(window.innerWidth >= 768);



    useEffect(() => {
        const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setShowDesktopImage(window.innerWidth >= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const welcomeStyle = {
        fontFamily: 'degular-display, sans-serif',
        fontSize: '24px',
        fontWeight: '500',
        lineHeight: '31.85px',
        letterSpacing: '0.03em',
        textAlign: 'center',
      };
    
      const headerStyle = {
        fontFamily: 'degular-display, sans-serif',
        fontSize: '65px',
        fontWeight: '600',
        lineHeight: '52.64px',
        letterSpacing: '0.02em',
        textAlign: 'center',
      };

      const descriptionStyle = {
        fontFamily: 'degular-display, sans-serif',
        fontSize: '24px',
        fontWeight: '400',
        lineHeight: '31.85px',
        letterSpacing: '0.03em',
        textAlign: 'center',
        width: windowWidth < 430 ? '20rem' : 'auto',
      };

      const buttonsContainerStyle = {
        width: windowWidth < 430 ? '100%' : '24rem', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        gap: '1rem',
        paddingLeft: windowWidth < 430 ? '1.5rem' : '0',
        paddingRight: windowWidth < 430 ? '1.5rem' : '0',
        position: 'relative',
    };

      const buttonTextStyle = {
        fontFamily: 'degular-display, sans-serif',
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '26.54px',
        letterSpacing: '0.04em',
        textAlign: 'center',
        color: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '1'

      };

      const gradientButtonStyle = {
        background: 'linear-gradient(21.99deg, #FF4D00 15.74%, #FF6914 86.97%)',
      };

      const gradientServicesButtonStyle = {
        background: 'linear-gradient(21.99deg, #474747 0%, #323232 100%)',
      }

      const buttonStyle = {
        ...buttonTextStyle,
        ...gradientButtonStyle,
      };

      const servicesButtonStyle = {
        ...buttonTextStyle,
        ...gradientServicesButtonStyle,
      }

      const imageStyle = {
        position: 'absolute',
    };

    const desktopImageStyle = {
        ...imageStyle,
        top: '-68%',
        right: windowWidth >= 768 ? 'calc(50% - 610px/2)' : '50%', 
        width: '60%'
    };
    const mobileImageStyle = {
        ...imageStyle,
        top: '-70%',
        right: 'calc(50% - 50vw)',
        width: '30%',
    };


  return (
    <div className="flex justify-center items-center mt-12 mb-72 sm:mt-52 w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-[666px] xl:max-w-[666px] mx-auto">
      <div className="h-72 flex flex-col justify-start items-center gap-6">
        <div style={welcomeStyle} className="text-zinc-800">WELCOME</div>
        <div style={headerStyle} className="text-zinc-800">Transforming ideas into engaging designs</div>
        <div style={descriptionStyle} className="text-zinc-800">Good designs you’ll love and actually use</div>
        <div style={buttonsContainerStyle}>
          <Link to="/services" className="grow shrink basis-0 h-12 py-2.5 bg-zinc-800 rounded-3xl justify-center items-center hover:brightness-125 customButton" style={servicesButtonStyle}>
            <div style={buttonTextStyle}>Our Services</div>
          </Link>
          <Link to="/contact" className="grow shrink basis-0 h-12  py-2.5 bg-custom-orange rounded-3xl justify-center hover:brightness-105 items-center customButton hover-effect" style={buttonStyle}>
            <div style={buttonTextStyle}>Get In Touch</div>
          </Link>
          {showDesktopImage && (
                <img src={desktopImage} alt="Desktop version" style={desktopImageStyle} />
            )}
            {!showDesktopImage && (
                <img src={mobileImage} alt="Mobile version" style={mobileImageStyle} />
            )}
        </div>
       
      </div>
     
    </div>
  );
};

export default Welcome;
