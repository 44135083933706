import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const desktopStyles = {
  container: { maxWidth: '320px' },
  heading: {
    fontFamily: 'degular-display, sans-serif',
    fontSize: '36px',
    fontWeight: '500',
    lineHeight: '48px',
    letterSpacing: '0.02em',
    textAlign: 'left',
    color: '#1F2937',
  },
  paragraph: {
    fontFamily: 'degular-display, sans-serif',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '21px',
    letterSpacing: '0.03em',
    textAlign: 'left',
    color: '#1F2937',
    marginTop: '20px', 
  },
  buttonTextStyle: {
    fontFamily: "degular-display, sans-serif",
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '21.23px',
    letterSpacing: '0.04em',
    textAlign: 'left',
    color: '#FFFFFF',
    
  }
};


const mobileStyles = {
  container: { maxWidth: '430px', padding: '0 18px'},
  heading: {
    fontFamily: 'degular-display',
    fontSize: '44px',
    fontWeight: '500',
    lineHeight: '58.39px',
    letterSpacing: '0.02em',
    textAlign: 'left',
    color: '#1F2937',
  },
  paragraph: {
    fontFamily: 'degular-display',
    fontSize: '22px',
    fontWeight: '300',
    lineHeight: '29.19px',
    letterSpacing: '0.03em',
    textAlign: 'left',
    color: '#1F2937',   
  },
  buttonTextStyle: {
    fontFamily: 'degular-display',
    fontSize: '22px',
    fontWeight: '500',
    lineHeight: '29.19px',
    letterSpacing: '0.04em',
    textAlign: 'left',
    color: '#FFFFFF',
  }
};

const gradientButtonStyle = {
  background: 'linear-gradient(21.99deg, #FF4D00 15.74%, #FF6914 86.97%)',
};

const AboutPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  

  const styles = isMobile ? mobileStyles : desktopStyles;

  const buttonStyle = {
    ...styles.buttonTextStyle,
    ...gradientButtonStyle,
  };

  return (
    <div className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto px-4 mt-2">
    <h1 style={styles.heading}>
        About Us
      </h1>
      <p style={isMobile ? {...styles.paragraph, paddingTop: '20px'} : styles.paragraph}>
        We are a small graphic design studio specializing in quickly turning your ideas into designs you’ll love and actually use.
        <br/><br/>
        We are committed to serving individuals and small businesses in our community to deliver personalized design services.
        <br/><br/>
        Our focus is that your project or business stand out with impactful visuals that leave a lasting impression.
      </p>
      <Link to="/contact" className="no-underline">
      <div className="mt-7 w-full md:w-48 h-14 md:h-10 px-5 py-2.5 rounded-full flex justify-center items-center gap-2.5 hover:brightness-105 customButton" style={buttonStyle}>
          Get in touch with us
        </div>
      </Link>
    </div>
  );
};

export default AboutPage;
