import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import FooterShape from '../footer2.png';
import AnnouncementBar from './AnnouncementBar';

const Layout = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const footerTextStyle = {
    fontFamily: 'degular-display, sans-serif',
    fontSize: isMobile ? '16px' : '14px',
    fontWeight: '300',
    lineHeight: isMobile ? '21.23px' : '16px',
    letterSpacing: '0.04em',
    textAlign: 'center',
    zIndex: '2',
    position: 'relative',
  };
    
  const footerLinkStyle = {
    fontFamily: 'degular-display, sans-serif',
    fontSize: isMobile ? '16px' : '14px',
    fontWeight: '300',
    lineHeight: isMobile ? '21.23px' : '14px',
    letterSpacing: '0.04em',
    textAlign: 'center',
    color: '#4B5563',
    zIndex: '2',
    position: 'relative',
  };

  const footerShapeStyle = {
    backgroundImage: `url(${FooterShape})`,
    backgroundPosition: 'center bottom',
    backgroundSize: isMobile ? 'cover' : 'auto',
    backgroundRepeat: 'no-repeat',
    height: '160px',
    width: '100%',
    position: 'absolute',
    bottom: '0',
    left: '0',
    zIndex: '1',
  };

  const footerStyle = {
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'center',
    padding: '20px 0',
    position: 'relative',
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow pt-[165px]">{children}</main>
      {/* <AnnouncementBar /> */}
      <footer className="mt-10 w-full" style={footerStyle}>
        <div style={footerShapeStyle} className="footer-shape pointer-events-none"></div>
        <div style={footerTextStyle}>
          © 2024 Design Path Co.
        </div>
        <div className="text-center mt-1" style={footerLinkStyle}>
          <Link to="/privacy-policy" className="underline" style={footerLinkStyle}>
            Privacy
          </Link>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
