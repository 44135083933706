import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ServicesPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const services = [
    "Advertisements", "Websites", "Mobile Apps", "UI & UX Design", "Branding", 
    "Newsletters", "Graphics & Signage", "Brochures", "Restaurant Menus*", 
    "Business Cards", "Print Stationery", "Copy-Editing", "Proofreading", 
  ];

  const textStyle = {
    fontFamily: 'degular-display, sans-serif',
    fontSize: isMobile ? '28px' : '18px',
    fontWeight: '400',
    lineHeight: isMobile ? '37.16px' : '23.89px',
    letterSpacing: '0.03em',
    textAlign: 'left',
  };

  const starStyle = {
    fontFamily: 'degular-display, sans-serif',
    fontSize: isMobile ? '28px' : '18px',
    fontWeight: '400',
    lineHeight: isMobile ? '37.16px' : '23.89px',
    letterSpacing: '0.03em',
    textAlign: 'left',
  };

  const TextStyleBottomBold = {
    fontFamily: 'degular-display, sans-serif',
    fontSize: isMobile ? '24px' : '20px',
    fontWeight: '500',
    lineHeight: isMobile ? '31.85px' : '26.54px',
    letterSpacing: '0.03em',
    textAlign: 'left',
  };
  
  const TextStyleBottomRegular = {
    fontFamily: 'degular-display, sans-serif',
    fontSize: isMobile ? '22px' : '16px',
    fontWeight: '300',
    lineHeight: isMobile ? '29.19px' : '21.23px',
    letterSpacing: '0.03em',
    textAlign: 'left',
  };
  
  const TextStyleBottomSmall = {
    fontFamily: 'degular-display, sans-serif',
    fontSize: isMobile ? '18px' : '14px',
    fontWeight: '300',
    lineHeight: isMobile ? '23.89px' : '15.4px',
    letterSpacing: '0.03em',
    textAlign: 'left',
  };

  const newTextStyle = {
    fontFamily: 'degular-display, sans-serif',
    fontSize: isMobile ? '13.53px' : '11px',
    fontWeight: '600',
    lineHeight: isMobile ? '17.95px' : '13.27px',
    letterSpacing: '0.04em',
    textAlign: 'left',
    marginLeft: '4px',
    marginTop: '3px',
    color: '#FF4D00',
    border: '1.23px solid #FF4D00',
    padding: '2px 7px',
    borderRadius: '20px 20px 20px 20px',
  }

  const gradientButtonStyle = {
    background: 'linear-gradient(21.99deg, #FF4D00 15.74%, #FF6914 86.97%)',
  };

  const buttonTextStyle = {
    fontFamily: 'degular-display',
    fontSize: isMobile ? '22px' : '16px',
    fontWeight: '500',
    lineHeight: isMobile ? '21.23px' : '29.19px',
    letterSpacing: '0.04em',
    height: isMobile ? '60px' : '40px',
    textAlign: 'left',
    color: '#FFFFFF',
  }

  const buttonStyle = {
    ...buttonTextStyle,
    ...gradientButtonStyle,
  };

  return (
    <div className="flex flex-col items-center justify-center pb-10">
      <div className="flex flex-col justify-start items-start gap-4 max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl px-4 pt-2 mx-auto">
        <div
          className="text-zinc-800"
          style={{
            fontFamily: '"degular-display", sans-serif',
            fontSize: isMobile ? '44px' : '36px', 
            fontWeight: '500',
            lineHeight: isMobile ? '58.39px' : '47.77px',
            letterSpacing: '0.02em',
            textAlign: 'left',
          }}
        >
          Our Services
        </div>
        <div
          className=" text-zinc-800"
          style={{
            fontFamily: '"degular-display", sans-serif',
            fontSize: isMobile ? '22px' : '16px', 
            fontWeight: '300',
            lineHeight: isMobile ? '29.19px' : '21.23px',
            letterSpacing: '0.03em',
            textAlign: 'left',
          }}
        >
          We specialize in developing compelling visuals tailored to your unique needs. We offer a variety of design services for digital and print including:
        </div>
        <div className="">
          <div className="grid grid-cols-[auto_1fr] gap-x-4 justify-items-start mx-auto">
            {services.map((service) => (
              <React.Fragment key={service}>
                <span style={starStyle} className="text-zinc-800">✨</span>
                <span style={textStyle} className="text-zinc-800">{service}</span>
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="w-full border-t border-stone-200"></div>
        <div className="">
          <p className="mb-1" style={{...TextStyleBottomBold, display: 'flex', alignItems: 'center'}}>
            <span>_Web Development |</span>
            <span style={newTextStyle}>NEW</span>
            </p>
          <p style={TextStyleBottomRegular}>
            We are now offering front-end and back-end web development services where we can build websites and apps for your projects.
          </p>
        </div>
        <div className="w-full border-t border-stone-200"></div>
        <div className="">
          <p className="mb-1" style={TextStyleBottomBold}>
            Not seeing what you’re looking for?
          </p>
          <p style={TextStyleBottomRegular}>
          If you’re seeking design services not shown in this list, please contact us to see how we can help you with your next project.
          </p>
          <Link to="/contact" className="no-underline">
            <div className="mt-5 w-full md:w-48 px-5 rounded-full flex justify-center items-center gap-2.5 customButton hover:brightness-105" style={buttonStyle}>
                Get in touch with us
              </div>
            </Link>
        <div className="w-full border-t border-stone-200 mt-7"></div>
          <p style={TextStyleBottomSmall} className="text-zinc-800 mt-4">
            * Special monthly subscription services available to local food businesses and restaurants.
          </p>
        </div>       
      </div>
    </div>
  );
};

export default ServicesPage;

