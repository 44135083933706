import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import NavbarLogo from './NavbarLogo';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showBorder, setShowBorder] = useState(false);
  const menuRef = useRef();
  const blurredBoxRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      if (!blurredBoxRef.current) return;


      const scrollThreshold = 50;

      if (window.pageYOffset > scrollThreshold) {
        setShowBorder(true);
      } else {
        setShowBorder(false);
      }

    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const activeLinkStyle = {
    color: '#EA580C',
    backgroundColor: '#FEE2E2',
  };

  const navbarStyles = {
    position: 'sticky',
    top: 0,
    zIndex: 100,
    width: '100%',
  };

  const barStyles = {
    backdropFilter: 'blur(12px)',
    '-webkit-backdrop-filter': 'blur(12px)',
    borderBottom: showBorder ? '1px solid #E8E0DC' : 'none',
    left: '130px',
    right: '0',
    zIndex: 0,
    '@media (min-width: 768px)': {
      right: 'max(calc(50% - 700px), 1rem)',
    },
  };



  return (
    <div className="fixed top-0 left-0 right-0 z-50">
    <div className="flex justify-center items-center mx-auto relative max-w-7xl">
      <div
        ref={blurredBoxRef}
        style={barStyles}
        className="absolute top-0 h-28 bg-stone-100 bg-opacity-40"
      ></div>
      <NavLink to="/welcome" className="absolute left-0 top-0 w-[137px] h-[137px] z-10">
          <NavbarLogo />
        </NavLink>
      <div className="md:hidden absolute right-4 top-12">
      <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
        {isMenuOpen ? (
          // X icon
          <svg className="h-8 w-8" style={{ fill: "#323232" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        ) : (
          // Menu icon
          <svg className="h-8 w-8" style={{ fill: "#323232" }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        )}
      </button>
      </div>
      {/* Desktop navigation links */}
      <div className=" justify-start items-center gap-7 absolute right-4 top-12 hidden md:flex">
      <NavLink to="/" className={({ isActive }) => `text-right font-degular font-semibold text-base tracking-wider ${isActive ? 'text-orange-600' : 'text-zinc-800'}`} onClick={() => setIsMenuOpen(false)}>
        Welcome
      </NavLink>
      <NavLink to="/services" className={({ isActive }) => `text-right font-degular font-semibold text-base tracking-wider ${isActive ? 'text-orange-600' : 'text-zinc-800'}`} onClick={() => setIsMenuOpen(false)}>
          Our Services
        </NavLink>
      <NavLink to="/about" className={({ isActive }) => `text-right font-degular font-semibold text-base tracking-wider ${isActive ? 'text-orange-600' : 'text-zinc-800'}`} onClick={() => setIsMenuOpen(false)}>
          About Us
        </NavLink>
        <NavLink to="/contact" className={({ isActive }) => `text-right font-degular font-semibold text-base tracking-wider ${isActive ? 'text-orange-600' : 'text-zinc-800'}`} onClick={() => setIsMenuOpen(false)}>
          Get In Touch
        </NavLink>
      </div>

      {/* Mobile menu */}
      <div ref={menuRef} className={`absolute right-4 top-20 z-10 ${isMenuOpen ? 'flex' : 'hidden'} md:hidden flex-col bg-white rounded-md shadow border p-3`} style={{ width: 'calc(100% - 2rem)', maxWidth: '15rem' }}>
      <NavLink to="/" style={({ isActive }) => isActive ? activeLinkStyle : null} className="pl-5 h-14 flex items-center text-zinc-800 text-lg font-degular tracking-wide w-full" onClick={() => setIsMenuOpen(false)}>
        Welcome
      </NavLink>
      <NavLink to="/services" style={({ isActive }) => isActive ? activeLinkStyle : null} className="pl-5 h-14 flex items-center text-zinc-800 text-lg font-degular tracking-wide w-full" onClick={() => setIsMenuOpen(false)}>
          Our Services
        </NavLink>
      <NavLink to="/about" style={({ isActive }) => isActive ? activeLinkStyle : null} className="pl-5 h-14 flex items-center text-zinc-800 text-lg font-degular tracking-wide w-full" onClick={() => setIsMenuOpen(false)}>
          About Us
        </NavLink>
        <NavLink to="/contact" style={({ isActive }) => isActive ? activeLinkStyle : null} className="pl-5 h-14 flex items-center text-zinc-800 text-lg font-degular tracking-wide w-full" onClick={() => setIsMenuOpen(false)}>
          Get In Touch
        </NavLink>
      </div>

    </div>
    </div>
  );
};

export default Navbar;
