import React, { useState, useEffect, useRef } from 'react';
import ChevronDownIcon from '../CaretDown.svg';

const CustomDropdown = ({ options, onSelect, hasError, selectedValue, resetCounter, isMobile}) => {
  console.log("CustomDropdown hasError:", hasError);
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Topic of Interest");
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    setSelectedOption("Topic of Interest");
  }, [resetCounter]);


  const placeholderStyle = {
    position: 'absolute',
    left: '9px',
    top: isFocused || selectedOption !== "Topic of Interest" ? '0' : '50%', 
    transform: isFocused || selectedOption !== "Topic of Interest" ? 'translateY(0)' : 'translateY(-50%)',
    transition: '0.2s ease all',
    fontSize: (isMobile && !isFocused && selectedOption === "Topic of Interest") ? '22px' : 
    (isMobile && (isFocused || selectedOption !== "Topic of Interest")) ? '14px' : 
    (isFocused || selectedOption !== "Topic of Interest") ? '10px' : '16px',    color: isFocused ? '#FF4D00' : '#A8A8A8',
    fontWeight: isFocused || selectedOption !== "Topic of Interest" ? 400 : 400,
    backgroundColor: 'transparent',
    padding: '0 4px',
    pointerEvents: 'none', 
    zIndex: 20,
  };

  const handleSelect = (option) => {
    setSelectedOption(option.label);
    onSelect(option);
    setIsOpen(false);
    setIsFocused(true);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsFocused(true);
  };

  

  return (
    <div className="relative w-full" ref={dropdownRef}>
    <div className="relative w-full" tabIndex="0" onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}>
    <div className="cursor-pointer w-full" onClick={() => setIsOpen(!isOpen)}>
    <div className={`flex items-center justify-between px-3.5 py-2 ${isFocused ? '' : 'bg-stone-50'} rounded relative`} 
     style={{
      minHeight: isMobile ? '60px' : '40px',
      borderColor: hasError ? '#f87171' : (isFocused ? 'rgba(255, 77, 0, 1)' : '#e5e7eb'),
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '0.375rem',
      backgroundColor: isFocused ? 'rgba(250, 250, 249, 1)' : '',
      paddingTop: isMobile ? '15px' : '10px',
      paddingBottom: isMobile ? '10px' : '0',
      boxShadow: isFocused ? '0 0 8px rgba(255, 77, 0, 0.6)' : 'none',
     }}>
        <div style={placeholderStyle}>Topic of Interest</div>
        <div className="flex items-center justify-between w-full">
          <span className={`text-zinc-800 ${selectedOption === "Topic of Interest" ? 'opacity-0' : 'opacity-100'} pt-1`}>
            {selectedOption || 'Select an option'}
          </span>
          <img src={ChevronDownIcon} className="w-4 h-15 absolute right-4" alt="Dropdown" style={{ top: '50%', transform: 'translateY(-50%)' }} />
        </div>
      </div>
    </div>

      {isOpen && (
        <div className="absolute mt-1 w-full py-2 bg-white rounded shadow border border-stone-200 z-10">
          {options.map((option, index) => (
            <div 
              key={index} 
              className={`px-3.5 ${isMobile ? 'py-3' : 'py-2'} hover:bg-red-50 cursor-pointer`} 
              onClick={() => handleSelect(option)}>
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
    </div>
  );
};

export default CustomDropdown;
