import React, { useState, useEffect } from 'react';

const NavbarLogo = () => {
  const [scaleY, setScaleY] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const overscrollDistance = -Math.min(0, window.scrollY);
      const newScaleY = 1 + overscrollDistance / 500;

      setScaleY(newScaleY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const logoStyle = {
    transform: `scaleY(${scaleY})`,
    transition: 'transform 0.05s ease-out',
  };

  return (
    <div className="relative w-full h-40 z-50 md:h-36" style={logoStyle}>
      {/* Mobile Layout */}
      <div className="absolute w-96 h-96 left-[-165px] top-[-240px] md:hidden">
        <div className="w-96 h-96 left-0 top-0 absolute bg-custom-orange rounded-full" />
        <div
          className="absolute left-[195.90px] top-[290px] text-white text-4xl font-extrabold leading-7 transform rotate-[7.80deg] origin-top-left"
          style={{
            fontFamily: '"Noway", sans-serif',
          }}
        >
          DESIGN <br />
          <span style={{ letterSpacing: '-2.7px' }}>P</span>
          <span style={{ letterSpacing: '-3.2px' }}>A</span>
          T<span style={{ letterSpacing: '-2.2px' }}>H</span>
        </div>
      </div>
      {/* Desktop Layout */}
      <div className="hidden md:block md:absolute md:w-[414px] md:h-[137px] md:left-0 md:top-[-277px]">
        <div className="w-[414px] h-[414px] absolute left-0 top-0 bg-custom-orange rounded-full" />
        <div
          className="absolute left-[186.59px] top-[309.25px] text-white text-[43.75px] font-bold leading-[33.69px] transform rotate-[7.80deg] origin-[0_0]"
          style={{
            fontFamily: '"Noway", sans-serif',
          }}
        >
          DESIGN <span style={{ letterSpacing: '-2.7px' }}>P</span>
          <span style={{ letterSpacing: '-3.2px' }}>A</span>T
          <span style={{ letterSpacing: '-2.2px' }}>H</span>
        </div>
      </div>
    </div>
  );
};

export default NavbarLogo;