import React from 'react';

const TermsOfService = () => {

    const titleStyle = {
        fontFamily: 'degular-display, sans-serif',
        fontSize: '36px',
        fontWeight: '600',
        lineHeight: '48px',
        letterSpacing: '0.02em',
        textAlign: 'left',
    };

    const bodyStyle = {
        fontFamily: 'degular-display, sans-serif',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '21px',
        letterSpacing: '0.03em',
        textAlign: 'left',
    };

    return (
        <div className="mx-auto px-10 pt-[137px] flex flex-col items-center gap-14 max-w-[660px] min-w-[430px]">
            <div className="flex flex-col items-start gap-3.5">
                <div style={titleStyle} className="text-zinc-800 w-full">Terms of Service</div>
                <div style={bodyStyle} className="text-zinc-800 w-full">
                ACKNOWLEDGMENT AND ACCEPTANCE OF TERMS AND POLICIES

Welcome to TechCrunch+ (the “Service”), offered by the applicable Verizon Media corporate entity identified in Section 7 below (“TechCrunch”), a service through which registered users can pay for access to premium features of the TechCrunch service.

Acceptance of Terms. By using and continuing to use the Service you and TechCrunch agree to the following terms and policies (and any successor urls): these Additional Paid Terms of Service for TechCrunch+ (“Additional Paid Terms”) (https://verizonmedia.com/policies/xw/en/extracrunch/index.html) which are in addition to the Verizon Media Terms of Service https://legal.yahoo.com/xw/en/yahoo/terms/otos/index.html)(collectively “Terms”) and the Verizon Media Privacy Policy (“Privacy Policy”) ( https://legal.yahoo.com/xw/en/yahoo/privacy/intl/index.html), all of which are hereby incorporated by reference.

Changes to Terms. TechCrunch reserves the right to update and change, from time to time, these Additional Paid Terms and all documents incorporated by reference. You can find the most recent version of the Terms at the URLs listed above. You should check these documents regularly for any updates. Your continued use of the Service will constitute acceptance of any modified terms. In the event of any inconsistency between these Additional Paid Terms and the Verizon Media Terms of Service, these Additional Paid Terms shall control.

YOU ARE AGREEING TO BE BOUND BY THESE ADDITIONAL PAID TERMS AND ALL DOCUMENTS INCORPORATED BY REFERENCE, SO PLEASE READ THE TERMS AND CONDITIONS CAREFULLY.

TERMS FOR TechCrunch+
In order to sign up for the Service, you must have a TechCrunch account. You must also provide your credit card number, billing and other information (“Registration Information”), which will be stored for the purposes of processing your subscription payments and in accordance with our Privacy Policy. You agree that the Registration Information you provide is true, valid, accurate, current, and complete and you will maintain and promptly update that information to keep it true, accurate, current, and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or TechCrunch has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, TechCrunch has the right to terminate your account and refuse you any and all current or future access to or use of the Service.
You, and not TechCrunch, are responsible for all purchases made through your TechCrunch account or in connection with your TechCrunch ID. TechCrunch is not responsible for unauthorized purchases or errors made using your TechCrunch ID or your TechCrunch account. You must resolve purchase-related disputes with and promptly report unauthorized use of your credit card to your credit card company, online payment service provider, merchant, or bank. Your liability for unauthorized use of your card may be limited by your card issuer’s agreement and/or by applicable law. In addition to the uses of account information authorized by these Additional Paid Terms or any incorporated documents, TechCrunch may access, preserve, monitor and disclose your card information in the good faith belief that such action is reasonably necessary to verify your account, to identify or resolve problems, or to respond to inquiries about your Service, but nothing in this section shall impose a duty on TechCrunch to make any such disclosures.

AUTOMATIC RENEWAL, REFUNDS, AND PAYMENT TERMS
You may purchase TechCrunch+ on a yearly or a monthly subscription basis.

Auto Renewal. The current fee for the Service will automatically and immediately be charged to your designated payment method or, if your designated payment method fails, to any other payment method you have on file with us. Details of your fees will be available from your account page.  The Service is provided on an auto-renewal basis commencing on the date that you sign up for the Service until you cancel. Your designated payment method or, if your designated payment method fails, any other payment method you have on file with us will be charged for renewal unless you cancel at least 48 hours before the end of the current period. You are entitled to cancel the Service at any time. To cancel the Service, log in to techcrunch.com and go to the your account page to manage your subscription and cancel your Service.
Cancellation for Auto-Renewed TechCrunch+.   If you choose to cancel your auto-renewed subscription of TechCrunch+, cancellation will cease the periodic (yearly or monthly) auto-renewal of the Service.  You will not receive a refund for the current billing period but will continue to receive your subscription for the remainder of the current billing period, regardless of the cancellation date. Your cancellation goes into effect after the current billing period has passed. For example, if the Service is for one month periods and you cancel on the 5th day of that month you will continue to get the service for that month and the Service will be cancelled at the next billing cycle.  Also for example, if the Service is for one year periods and you cancel on the 100th day of that year you will continue to get the service for that year and the Service will be cancelled at the next billing cycle.
Amount Charged for Service may Change. The amount charged may change. If you do not cancel the Service, you hereby consent that the payment method (or, if your designated payment method fails, any other payment method that you have on file) will automatically be charged the then-current fee for the Service at the commencement of each renewal period. We will provide you with notice of any price increase at least thirty (30) days in advance. Details of the current fees will be posted at https://techcrunch.com/subscribe. Fees charged for one service may not be credited towards other services. TechCrunch does not provide price protection or refunds in the event of a price reduction or promotional offering.
Discounts and Offers. TechCrunch in its discretion may offer discounts or special offers from time to time. If you have received an offer for a free trial period in connection with signing up for the Service, then you will not be charged for the first time until after your free trial period has ended. Once the offer or trial period ends, you will be auto-charged for the Service until you cancel. If you cancel during the trial period, you will not have access to the Service once the trial period ends, unless you purchase the Service. Subsequent billing dates will be on the monthly or annual anniversary of your first billing date. For example, if you purchase an annual subscription on October 15th and you get the first 30 days free and if you do not cancel within the first 30 days, then you will not be charged at all until November 14th (there are 31 days in October). Thereafter, you will be automatically charged again on November 14th of each subsequent year, until you decide to cancel. If you receive a discount or special offer during the current term, your billing date will extend for the additional period of the discount or special offer.
Users in the EU. If you are  a resident in the European Union, this Section 5 is without prejudice to your rights under Sections 14.13(i) and (j) of the Yahoo Terms of Service.  Please refer to these sections for details of your 14 day right to cancel following completion of your purchase. You may exercise your right to cancel via the links in Section 14.13(i) or via your TechCrunch account.
6. TERM AND TERMINATION

TechCrunch may, but has no duty, to terminate immediately the Service and refuse allowing any and all current or future access to or use of the Service by you if TechCrunch in its sole discretion concludes that: (a) you have provided any information that is invalid, untrue, inaccurate, not current or incomplete, or TechCrunch has reasonable grounds to suspect that such information is invalid, untrue, inaccurate, not current or incomplete, (b) you are engaged in fraudulent or illegal activities, (c) you are engaged in activities that may damage the rights of TechCrunch or others, or (d) you have failed to abide by the terms of these Additional Paid Terms, the Privacy Policy, or the Verizon Media Terms of Service or any incorporated terms. TechCrunch accepts no liability for information or content that is deleted due to an invalid credit card.

CONTRACTING PARTY, CHOICE OF LAW, AND LOCATION FOR RESOLVING DISPUTES AND OTHER LOCAL REGION PROVISIONS
The Service is provided by the company that offers the Service in your region as set out below (the “Applicable Verizon Media Company”).  For Canada, this Service is open to all residents of Canada, excluding Quebec.  The Applicable Verizon Media Company is the provider that you are contracting with for the Service.  Not all features may be available in your country or region. Different features may be available in different versions of the Service. The choice of law, the location for resolving disputes, certain defined terms (including the Applicable Verizon Media Company), and other important region specific provisions are set forth in the table below. If you have any questions, log in to techcrunch.com and go to your account page. From the account page, click on the help section to contact customer care.
                </div>
                
                <div className="w-full border-t border-stone-200"></div>
            </div>
        </div>
    );
};

export default TermsOfService;
